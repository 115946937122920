<template>
  <div id="keyboard-keys-info-box">
    <a-descriptions
      title="Keyboard hot Keys"
      :colon="false"
      size="small"
      :contentStyle="textStyle"
      id="keys-info"
    >
      <a-descriptions-item label="Space bar : " span="3">
        To select or deselect a step.
      </a-descriptions-item>
      <a-descriptions-item label="s : " span="3">
        Switch to next step.
      </a-descriptions-item>
      <a-descriptions-item label="w : " span="3">
        Switch to previous step.
      </a-descriptions-item>
      <a-descriptions-item label="d :" span="3">
        To increase Frame number.
      </a-descriptions-item>
      <a-descriptions-item label="a :" span="3">
        To decrease Frame number.
      </a-descriptions-item>
      <a-descriptions-item label="shift + d : " span="3">
        To increase Frames by 10.
      </a-descriptions-item>
      <a-descriptions-item label="shift + a : " span="3">
        To decrease Frames by 10.
      </a-descriptions-item>
      <a-descriptions-item label="f : " span="3">
        To save frame.
      </a-descriptions-item>
      <a-descriptions-item label="p : " span="3">
        To save annotation.
      </a-descriptions-item>
      <a-descriptions-item label="c : " span="3">
        To clear All labels.
      </a-descriptions-item>
    </a-descriptions>

    <a-typography-text class="bold">Actions:</a-typography-text>
    <div class="d-flex my-2">
      <a-button
        @click="emitter.emit('clear-all')"
        v-if="!isVisualize"
        id="annotate-video-clear-labels-btn"
        >Clear All Labels</a-button
      >
      <a-button
        @click="$emit('changeZoom', !zoomIn)"
        class="ml-3"
        v-if="totalFrames > 1800"
        id="annotate-video-zoom-btn"
      >
        Zoom
        <i
          class="ml-2 bi"
          :class="{
            'bi-zoom-in': !zoomIn,
            'bi-zoom-out': zoomIn,
          }"
        ></i>
      </a-button>
    </div>

    <div v-if="translationLanguage">
      <a-typography-text class="bold">Select Language:</a-typography-text>
      <a-select
        :options="languageOptions"
        class="w-50 d-block mt-2"
        size="small"
        v-model:value="taskLanguage"
      >
      </a-select>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: ['totalFrames', 'zoomIn', 'isVisualize'],
  emits: ['changeZoom'],
  data() {
    return {
      textStyle: {
        fontSize: '12px',
      },
      taskLanguage: '',
    };
  },
  created() {
    this.taskLanguage = this.selectedLang;
  },
  computed: {
    ...mapGetters(['translationLanguage', 'selectedLang']),
    languageOptions() {
      return [
        { value: 'English', label: 'ENGLISH' },
        {
          value: `${this.translationLanguage}`,
          label: `${this.translationLanguage?.toUpperCase()}`,
        },
      ];
    },
  },
  watch: {
    taskLanguage(value) {
      this.setSelectedLanguage(value);
    },
  },
  methods: {
    ...mapActions(['setSelectedLanguage']),
  },
};
</script>
<style>
#keys-info table td {
  padding-bottom: 2px;
}
</style>
